import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";

import Header, {
  LogoLink,
  NavLinks,
  NavLink as NavLinkBase,
} from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-6
`;

const Container = tw.div`relative -mx-8 -mt-8`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-primary-100`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8`;
const RightColumn = styled.div`
  background-image: url("images/sankim/sk1.jpg");
  ${tw`bg-green-500 bg-cover bg-center xl:ml-24 h-96 lg:h-auto lg:w-1/2 lg:flex-1`}
`;

const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block`}
  svg {
    ${tw`w-5 h-5 `}
  }
`;

export default ({
  navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/sobre">Sobre nós</NavLink>
      <NavLink href="/marcas">Nossas Marcas</NavLink>
      <NavLink href="/contato">Contato</NavLink>
      <NavLink href="https://www.facebook.com/sankim1996/" target="_blank"><SocialLink><FacebookIcon /></SocialLink></NavLink>
    </NavLinks>,
  ],
  heading = (
    <>
      Sankim Alimentos
      <wbr />
      <br />
      <span tw="text-primary-500">distribuindo qualidade.</span>
    </>
  ),
  description = "Somos uma empresa com 25 anos de atuação. Fundada em 1996, pelo Sr. Milton Jose dos Santos e Sr. Paulo Kimaid",
}) => {
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          <StyledHeader links={navLinks} collapseBreakpointClass="sm" />
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
          </Content>
        </LeftColumn>
        <RightColumn></RightColumn>
      </TwoColumn>
    </Container>
  );
};

import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";

import Home from "pages/Home.js";
import AboutUsPage from "pages/AboutUs.js";
import ContactUsPage from "pages/ContactUs.js";
import BlogIndexPage from "pages/BlogIndex.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/sobre">
          <AboutUsPage />
        </Route>
        <Route path="/contato">
          <ContactUsPage />
        </Route>
        <Route path="/marcas">
          <BlogIndexPage />
        </Route>
      </Switch>
    </Router>
  );
}

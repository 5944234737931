import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

import Congelados from "components/cards/brands/Congelados";
import Conservas from "components/cards/brands/Conservas";
import Frios from "components/cards/brands/Frios";
import Geleias from "components/cards/brands/Geleias";
import Laticinios from "components/cards/brands/Laticinios";
import Saudavel from "components/cards/brands/Saudavel";
import Temperos from "components/cards/brands/Temperos";
import Biscoitos from "components/cards/brands/Biscoitos";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>Nossas marcas</Heading>
          </HeadingRow>
          <Frios />
          <Laticinios />
          <Congelados />
          <Conservas />
          <Saudavel />
          <Geleias />
          <Temperos />
          <Biscoitos />
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

import BonduelleImg from "images/sankim/bonduelle.png";
import BufalaImg from "images/sankim/bufala.png";
import CatupiryImg from "images/sankim/catupiry.png";
import CiaDaCodornaImg from "images/sankim/cia_da_codorna.png";
import DeligourmetImg from "images/sankim/deligourmet.png";
import DonaBejaImg from "images/sankim/dona_beja.png";
import GranoImg from "images/sankim/grano.png";
import HaciendasImg from "images/sankim/haciendas.png";
import HomemadeImg from "images/sankim/homemade.png";
import JaguacyImg from "images/sankim/jaguacy.png";
import LifeCoImg from "images/sankim/life_co.png";
import MagazzinoImg from "images/sankim/magazzino.png";
import MaisFrutaImg from "images/sankim/mais_fruta.png";
import MisterBeefImg from "images/sankim/mister_beef.png";
import PolenghiImg from "images/sankim/polenghi.png";
import PrietoImg from "images/sankim/prieto.png";
import ProntinhaImg from "images/sankim/prontinha.png";
import QuataImg from "images/sankim/quata.png";
import RarImg from "images/sankim/rar.png";
import SaborMinasImg from "images/sankim/sabor_minas.png";
import SantaEdwigesImg from "images/sankim/santa_edwiges.png";
import SoetoImg from "images/sankim/soeto.png";
import TingImg from "images/sankim/ting.png";
import ValezaImg from "images/sankim/valeza.png";
import VerdeCampoImg from "images/sankim/verde_campo.png";


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 lg:pt-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col  max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;

export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: PolenghiImg,
      title: "Polenghi",
    },
    {
      imageSrc: PrietoImg,
      title: "Prieto",
    },
    {
      imageSrc: ProntinhaImg,
      title: "Prontinha",
    },
    {
      imageSrc: QuataImg,
      title: "Quatá",
    },
    {
      imageSrc: RarImg,
      title: "Rar",
    },
    {
      imageSrc: SaborMinasImg,
      title: "Sabor & Minas",
    },
    {
      imageSrc: SantaEdwigesImg,
      title: "Santa Edwiges",
    },
    {
      imageSrc: SoetoImg,
      title: "Soeto",
    },
    {
      imageSrc: TingImg,
      title: "Ting",
    },
    {
      imageSrc: ValezaImg,
      title: "Valeza",
    },
    {
      imageSrc: VerdeCampoImg,
      title: "Verde Campo",
    },
    {
      imageSrc: BonduelleImg,
      title: "Bonduelle",
    },
    {
      imageSrc: BufalaImg,
      title: "Búfala Almeida Prado",
    },
    {
      imageSrc: CatupiryImg,
      title: "Catupiry",
    },
    {
      imageSrc: CiaDaCodornaImg,
      title: "Cia da Codorna",
    },
    {
      imageSrc: DeligourmetImg,
      title: "DeliGourmet",
    },
    {
      imageSrc: DonaBejaImg,
      title: "Dona Beja",
    },
    {
      imageSrc: GranoImg,
      title: "Grano",
    },
    {
      imageSrc: HaciendasImg,
      title: "Haciendas",
    },
    {
      imageSrc: HomemadeImg,
      title: "Homemade",
    },
    {
      imageSrc: JaguacyImg,
      title: "Jaguacy",
    },
    {
      imageSrc: LifeCoImg,
      title: "LifeCo",
    },
    {
      imageSrc: MagazzinoImg,
      title: "Magazzino",
    },
    {
      imageSrc: MaisFrutaImg,
      title: "Mais Fruta",
    },
    {
      imageSrc: MisterBeefImg,
      title: "Mister Beef",
    },
  ];

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Algumas de nossas marcas</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}>
              <ChevronLeftIcon />
            </PrevButton>
            <NextButton onClick={sliderRef?.slickNext}>
              <ChevronRightIcon />
            </NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <img src={card.imageSrc} alt={card.title} style={{height: "90px", maxWidth: "unset", marginLeft: "50px"}}/>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};

import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

import BufalaImg from "images/sankim/bufala.png";
import QuataImg from "images/sankim/quata.png";
import RarImg from "images/sankim/rar.png";
import VerdeCampoImg from "images/sankim/verde_campo.png";
import PolenghiImg from "images/sankim/polenghi.png";
import ValezaImg from "images/sankim/valeza.png";
import DonaBejaImg from "images/sankim/dona_beja.png";
import CatupiryImg from "images/sankim/catupiry.png";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-12 lg:py-16`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col  max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;

export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: BufalaImg,
      title: "Búfala Almeida Prado",
    },
    {
      imageSrc: QuataImg,
      title: "Quata",
    },
    {
      imageSrc: RarImg,
      title: "RAR",
    },
    {
      imageSrc: VerdeCampoImg,
      title: "Verde Campo",
    },
    {
      imageSrc: PolenghiImg,
      title: "Polenghi",
    },
    {
      imageSrc: ValezaImg,
      title: "Valeza",
    },
    {
      imageSrc: DonaBejaImg,
      title: "Dona Beja",
    },
    {
      imageSrc: CatupiryImg,
      title: "Catupiry",
    },
  ];

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Latícinios</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}>
              <ChevronLeftIcon />
            </PrevButton>
            <NextButton onClick={sliderRef?.slickNext}>
              <ChevronRightIcon />
            </NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <img src={card.imageSrc} alt={card.title} style={{height: "90px", maxWidth: "unset", marginLeft: "50px"}}/>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;
const Map = tw.iframe``;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm />
      <ContactDetails
        heading="Onde estamos"
        subheading="Localização"
        description=""
        cards={[
          {
            title: "Bauru",
            description: (
              <>
                <Address>
                  <AddressLine>Rua Alagoas, Nº 9-51,</AddressLine>
                  <AddressLine>17030-024 Parque São Jorge</AddressLine>
                </Address>
                <Email>sankim@sankim.com.br</Email>
                <Phone>(14) 3234-5447</Phone>
              </>
            ),
          },
          {
            title: "  ",
            description: (
              <>
              <Map src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.74741294528!2d-49.050960684471534!3d-22.325390522997004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94bf677880cfb525%3A0xcbdcdbf75bf7887a!2sR.%20Alagoas%2C%209-51%20-%20Vila%20Coralina%2C%20Bauru%20-%20SP%2C%2017030-024!5e0!3m2!1spt-BR!2sbr!4v1602770420721!5m2!1spt-BR!2sbr" width="500" height="350"></Map>
              </>
            )
          }
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};

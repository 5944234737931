import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";

import Sk1Img from "images/sankim/sk1.jpg";
// import Sk2Img from "images/sankim/sk2.jpg";
import Sk3Img from "images/sankim/sk3.jpg";
import Sk4Img from "images/sankim/sk4.jpg";
import MapaAtuacaoImg from "images/sankim/mapa_atuacao.png";

import SatisfactionImg from "images/satisfaction.svg";
import GrowthImg from "images/growth.svg";
import EcologyImg from "images/ecology.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>Nossa história</Subheading>}
        heading="Somos uma empresa com 25 anos de atuação."
        description="Nossa empresa foi fundada em 1996, pelo Sr. Milton Jose dos Santos e Sr. Paulo Kimaid dando assim o nome de SANKIM. Realizando um trabalho de distribuição  e produtos de altíssima qualidade para atender o pequeno, médio e grande varejo.
        Estamos presentes em 2 Estados brasileiros, com unidade Própria de distribuição estrategicamente localizada e uma frota de caminhões refrigerados, o que nos permite trabalhar baseados em uma logística ágil e eficiente, atendendo com qualidade e eficiência a necessidades de nossos clientes."
        imageSrc={Sk4Img}
      />
      <MainFeature1
        subheading={<Subheading>Nossa Missão</Subheading>}
        heading="Queremos oferecer qualidade."
        description="Ser referência em qualidade por todos (Consumidores, Clientes, Funcionários e Fornecedores) como a que melhor Distribui os melhores produtos de sua linha e que melhor atende seus clientes."
        imageSrc={Sk3Img}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Nosso valores</Subheading>}
        heading="Valores da empresa"
        description="Nosso trabalho está orientado para a busca constante de:"
        cards={[
          {
            imageSrc: SatisfactionImg,
            title: "Satisfação do Cliente e do Consumidor",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
          {
            imageSrc: GrowthImg,
            title: "Realização e Crescimento do Funcionário",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
          {
            imageSrc: EcologyImg,
            title: "Crescimento da Empresa em harmonia com o Meio Ambiente",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
        ]}
        linkText=""
      />
      <MainFeature1
        subheading={<Subheading>Áreas de Atuação</Subheading>}
        heading="Onde estamos."
        description="Atuamos em 170 municípios de 2 importantes Estados do Brasil: Interior de São Paulo e Mato Grosso do Sul e atendendo uma população de 7 milhões de habitantes. "
        imageSrc={MapaAtuacaoImg}
      />
      
      <MainFeature1
        subheading={<Subheading>Segmentos</Subheading>}
        heading="Para quem oferecemos."
        description="Nossos segmentos são Padarias,
        Lanchonetes,
        Pizzarias,
        Conveniências,
        Lojas de produtos naturais,
        Supermercados,
        Restaurantes e
        Sorveteria."
        imageSrc={Sk1Img}
        textOnLeft={false}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
